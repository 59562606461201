export const MenuItems = [
    {
        title: 'About',
        url: '/about',
        cName: 'nav-links'
    },
    {
        title: 'Portfolio',
        url: '/portfolio',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '/contact',
        cName: 'nav-links'
    },
]

