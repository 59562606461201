import React from 'react';
import './WebsiteFront.css';

function WebsiteFront() {
    return (
        <div className='bio'>
            <h1 className='name-title'>cody hancock</h1>
            <p className='name-subtitle'>developer bio</p>
        </div>
    )
}

export default WebsiteFront;